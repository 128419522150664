<template>
	<div>
		<div class="lot">
			<div class="para-info">
				<b></b>
				<span>
					物联网行业应用
				</span>
				<div :class="showIndex==1?'selected':''" @click="queryTopCase('case_smart-construction-site')">智慧工地
				</div>
				<div :class="showIndex==2?'selected':''" @click="queryTopCase('case_smart-park')">智慧园区</div>
				<div :class="showIndex==3?'selected':''" @click="queryTopCase('case_smart-building')">智慧楼宇</div>
			</div>
			<div class="lot-detail">
				<div class="lot-detail-main"
					:style="topCase!=null&&topCase.linkTo!=null&&topCase.linkTo!=''?'cursor:pointer':''"
					@click="goToLink(topCase.linkTo)">
					<el-image style="width: 550px;height: 340px;" fit="cover"
						:src="topCase==null||topCase.prevImage==''?'':global.getImage(topCase.prevImage,2,550)">
						<div slot="placeholder" class="image-loading">
							加载中...
						</div>
					</el-image>
				</div>
				<div class="lot-detail-content">
					<div class="lot-detail-content-title">{{topCase==null?'':topCase.name}}</div>
					<div class="lot-detail-content-html" v-html="topCase==null?'':topCase.detail"></div>
				</div>
			</div>
		</div>
		<div class="company-info"
			:style="'background: url('+global.getImage('company-info.jpg')+') no-repeat center/cover;'">
			<p>湖南畅飞信息科技有限公司</p>
			<p>湖南畅飞信息科技致力于打造成国内一流的科技型、智能型、节能环保型物联网软硬件技术及设备生产研发和解决方案提供商</p>
			<div class="company-info-detail">
				<div>
					<el-image :src="require('@/assets/info_1.png')" fit="contain"></el-image>
					<div>物联网技术</div>
				</div>
				<div>
					<el-image :src="require('@/assets/info_2.png')" fit="contain"></el-image>
					<div>人工智能技术</div>
				</div>
				<div>
					<el-image :src="require('@/assets/info_3.png')" fit="contain"></el-image>
					<div>区块链技术</div>
				</div>
			</div>
			<div class="company-info-axios">
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
				<div class="company-info-dot">
					<div></div>
				</div>
				<div class="company-info-line">
					<div></div>
				</div>
			</div>
			<div class="company-info-detail under-line">
				<div>
					<el-image :src="require('@/assets/info_4.png')" fit="contain"></el-image>
					<div>大数据平台</div>
				</div>
				<div>
					<el-image :src="require('@/assets/info_5.png')" fit="contain"></el-image>
					<div>云计算</div>
				</div>
				<div>
					<el-image :src="require('@/assets/info_6.png')" fit="contain"></el-image>
					<div>5G通讯技术</div>
				</div>
			</div>
		</div>
		<div class="product">
			<div class="para-info">
				<b></b>
				<span>
					产品中心
					<p>Product Center</p>
				</span>
				<div class="selected" @click="$router.push('product-list')">查看全部</div>

			</div>
			<div class="product-list">

				<div v-for="item in productList" class="product-list-item" :key="item.id"
					@click="$router.push('/product-detail?id='+item.id)">
					<el-image class="product-image" fit="contain" lazy
						:src="global.getImage(item.prevImage,2,338,209) ">
					</el-image>
					<div class="product-name">
						<span>
							{{item.name}}
						</span>
					</div>
				</div>

			</div>

		</div>
		<div class="aspect">
			<el-image :src="require('@/assets/aspect_1.png')"></el-image>
			<div class="aspect-info">
				<div>实力雄厚</div>
				<div>POWERFUL</div>
			</div>
			<el-image :src="require('@/assets/aspect_2.png')"></el-image>
			<div class="aspect-info">
				<div>供货稳定</div>
				<div>STABLE</div>
			</div>
			<el-image :src="require('@/assets/aspect_3.png')"></el-image>
			<div class="aspect-info">
				<div>品类齐全</div>
				<div>COMPLETE</div>
			</div>
			<el-image :src="require('@/assets/aspect_4.png')"></el-image>
			<div class="aspect-info">
				<div>品质保证</div>
				<div>ENSURE</div>
			</div>
		</div>
		<div class="about">
			<div class="about-detail">
				<div class="about-detail-title">
					<div class="about-hr">
						<div></div>
					</div>
					<div class="about-name">
						关于<font>畅飞科技</font>
					</div>
					<div class="about-hr">
						<div></div>
					</div>
				</div>
				<div class="about-detail-intro">
					专业从事物联网技术以及相关产品的研发、生产、销售、实施、售后服务为一体的整套解决方案提供商
				</div>
				<div class="about-detail-content">
					<div>
						<p>
							湖南畅飞信息科技有限公司2015年成立，拥有30多名专业软件技术研发人员。自主研发高运算能力、高可靠性的互联网数据采集、挖掘、分析与加工系统。2018年深圳研发部成立，开始进入图像识别、语音识别、物联网等相关领域技术研发。
							构建以视频技术为核心的智慧物联网平台，包括相应的硬件设备（IPC、人脸识别一体机、边缘计算、智能NVR）和数字平台，推进人工智能、大数据、云计算、5G、区块链等新一代技术的产业化应用。系列产品广泛应用于物联网、大数据、在线旅游、智慧景区/园区/楼宇、电网、电力、金融、教育、直播、在线购物等行业，为用户提供优质的大数据与物联网技术服务，业务涵盖Web数据挖掘、物联网技术服务、移动互联网数据服务、API数据接口服务及APP应用程序开发与H5开发，同时为用户提供行业解决方案咨询服务与产品售后服务。
						</p>
						<button @click="$router.push('/company-intro')">查看更多+</button>
					</div>
					<el-image fit="cover" lazy :src="global.getImage('intro.jpg',2,575,430)"></el-image>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "@/api/index";

	export default {
		data: function() {
			return {
				showIndex: 1,
				topCase: {
					name: "",
					prevImage: "",
					linkTo: "",
					detail: ""
				},
				productList: []
			};
		},
		mounted() {
			this.init();
		},
		methods: {
			init() {
				this.queryTopCase('case_smart-construction-site');
				this.queryProduct();
			},
			queryTopCase(caseId) {
				if (caseId == 'case_smart-construction-site')
					this.showIndex = 1;
				else if (caseId == 'case_smart-park')
					this.showIndex = 2;
				else if (caseId == 'case_smart-building')
					this.showIndex = 3;
				var self = this;
				api.getTopCase(caseId).then(function(resp) {
					if (resp.success) {
						self.topCase = resp.data
					}
				});
			},
			queryProduct() {
				var self = this;
				api.getProductList().then(function(resp) {
					if (resp.success) {
						self.productList = resp.data
					}
				});
			},
			goToLink(url) {
				if (url.indexOf('http://') == 0 || url.indexOf('https://') == 0) {
					window.open(url);
				} else {
					this.$router.push(url);
				}
			}
		},
	};
</script>

<style lang="scss" scoped="true">
	.banner-image {
		display: block !important;
	}

	.image-loading {
		color: $primary-font-color-light;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: $object-background-color;
	}

	.para-info {
		display: flex;
		width: 1100px;
		align-items: center;

		margin-bottom: 40px;

		b {
			width: 1em;
			height: .2em;
			display: block;
			background-color: $primary-red;
			margin-right: 1em;
		}

		span {
			flex-grow: 1;
			text-align: left;
			font-size: 1.5em;
			color: $primary-font-color-deep;

			p {
				margin: 0;
				font-size: .8em;
				color: $primary-font-color-light;
			}
		}

		div {
			width: 7em;
			padding: 5px;
			border-radius: 2em;
			color: $primary-font-color-light;
			border: 1px solid $primary-font-color-light;
			margin-left: 1.5em;
			cursor: pointer;
			text-align: center;
		}

		.selected {
			background-color: $primary-red;
			color: #fff;
			border: 1px solid $primary-red;
		}
		.selected:hover{
			text-decoration: underline;
		}
	}

	.lot {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-top: 50px;
		padding-bottom: 50px;
		background: #fff;

		.lot-detail {
			display: flex;
			width: 1100px;

			.lot-detail-main {
				display: flex;
				flex-direction: column;
				width: 550px;
			}

			.lot-detail-content {
				width: 550px;
				padding-left: 130px;

				.lot-detail-content-title {
					font-size: 1.5em;
					color: $primary-font-color-deep;
				}

				::v-deep .lot-detail-content-html {
					img {
						max-width: 100% !important;
					}
				}
			}
		}
	}

	.company-info {
		/*background: url(~@/assets/company-info.jpg) no-repeat center/cover;*/
		height: 515px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		overflow: hidden;
		p:first-child {
			font-size: 2em;
			color: #fff;
			margin-top: 0;
		}

		p:nth-child(2) {
			color: #fff;
			opacity: .8;
			margin-bottom: 4em;
		}

		.company-info-detail {
			color: #fff;
			display: flex;
			width: 1215px;
			margin-left: 1.5em;
		}

		.company-info-detail>div {
			width: 33.33%;
			text-align: left;
			padding: .5em 0 1em 1em;
			border-left: 1px solid #fff;

			.el-image {
				height: 28px;
			}
		}

		.under-line {
			margin-left: 430px !important;
		}

		.company-info-axios {
			display: flex;
			width: 1215px;
			justify-content: center;
			align-items: center;

			.company-info-dot {
				width: 1em;
				height: 1em;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.25);
				padding: .3em;

				div {
					height: 100%;
					width: 100%;
					border-radius: 50%;
					background-color: #fff;
				}
			}

			.company-info-line {
				flex-grow: 1;

				div {
					border-bottom: 1px solid #fff;
					width: 100%;
				}
			}
		}


	}

	.product {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 50px;
		margin-bottom: 50px;

		.product-list {
			display: flex;
			width: 1140px;
			flex-wrap: wrap;
			margin-left: -40px;

			.product-list-item {
				cursor: pointer;
				margin-left: 40px;
				border: 1px solid $primary-font-color-disabled;
				width: 338px;

				.product-image {
					width: 338px;
					height: 209px;
				}

				margin-bottom: 40px;

				.product-name {
					padding: 1em;
					background: #fff;

					span {
						text-overflow: -o-ellipsis-lastline;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
					}

				}
			}

			.product-list-item:hover {
				opacity: .5;
			}
		}
	}

	.aspect {
		background-color: $primary-red;
		height: 200px;
		display: flex;
		justify-content: center;
		align-items: center;

		.el-image {
			height: 90px;
			width: 90px;
		}

		.aspect-info {
			color: #fff;
			padding: 0 70px 0 20px;
			border-right: 1px solid #fff;
			margin-right: 40px;
		}

		.el-image:first-child {
			margin-left: 80px;
		}

		.aspect-info:last-child {
			margin-right: 0;
			border: 0;
		}

		.aspect-info div:first-child {
			font-size: 1.5em;
			line-height: 2em;
		}

		.aspect-info div:last-child {
			opacity: .5;
		}
	}

	.about {
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 50px;
		margin-bottom: 50px;

		.about-detail {
			width: 1100px;

			.about-detail-title {
				display: flex;
				justify-content: center;

				.about-hr {
					width: 200px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					overflow: hidden;

					div {
						width: 100%;
						border-top: 1px solid $primary-font-color-light;
					}
				}

				.about-name {
					border-left: 1px solid $primary-font-color-light;
					border-right: 1px solid $primary-font-color-light;
					padding: 0 1.6em 0 1.6em;
					font-size: 1.6em;
					color: $primary-font-color-deep;

					font {
						color: $primary-red;
					}
				}
			}

			.about-detail-intro {
				text-align: center;
				margin-top: 2em;
				color: $primary-font-color;
			}

			.about-detail-content {
				display: flex;
				margin-top: 2em;

				div {
					flex-grow: 1;
					background-color: #fff;
					padding: 2em 2em 1em 2em;
					text-align: left;
					width: 450px;

					p {
						margin: 0;
						text-align: left;
						text-indent: 2em;
						line-height: 1.5em;
						color: $primary-font-color;
					}

					button {
						background: #fff;
						border: 1px solid $primary-font-color-light;
						padding: 5px 30px;
						border-radius: 30px;
						color: $primary-font-color-light;
						margin-top: 1em;
					}
				}

				.el-image {
					width: 650px;
				}
			}
		}
	}
</style>
